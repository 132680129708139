/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable max-lines */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filterUndefined } from 'core/helpers';
import {
  GuidString,
  LayerViewType,
  MapItem,
  MapItemType,
  MapMode,
  MapSelectionMode,
  MapsFilter,
  ReducedMap,
  SelectedMapItem,
} from 'core/models';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  delay,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import { MapLayerView, PointerUpArgs } from '../models';
import { mapToReduced } from '../models/map-data';
import { MapDataService } from './map-data.service';
import { StageService } from './stage.service';

import { environment } from '@environment';
import { Viewport } from 'pixi-viewport';
import { InteractionEvent } from 'pixi.js';
import * as fromMaps from 'store-modules/maps-store';
import { MapItemContainer, isMapItemContainer, isZoneMapItemContainer } from '../layers';
import { isZoneMapLayerView } from '../layers/zone/zone-map-layer-view';
import { MapCommunicationService } from './map-communication.service';

@Injectable({
  providedIn: 'root',
})
export class MapLayerService {
  readonly EXTERNAL_SELECTION_DEBOUNCE_TIME = 400;

  layerTypes: LayerViewType[] = [
    LayerViewType.NavigationLayers,
    LayerViewType.Background,
    LayerViewType.GraphLayer,
  ];

  private readonly list = new Map<LayerViewType, MapLayerView>();
  private readonly filter = new Map<LayerViewType, (filter: MapsFilter) => boolean>();

  // Created or Unsubscribe
  private readonly isReady = new BehaviorSubject<boolean>(false);
  mapReady$: Observable<boolean> = this.isReady.asObservable();

  private readonly ngUnsubscribe = new Subject<void>();

  // Ready and Map Set/Changed
  mapChanged$!: Observable<ReducedMap>;
  mapFilterChanged$!: Observable<MapsFilter>;
  mapModeChanged$!: Observable<MapMode | undefined>;

  get mapRotation$(): Observable<number> {
    return this.stageService.rotation$;
  }

  get mapScale$(): Observable<number> {
    return this.stageService.scale$;
  }

  get mapResolution$(): Observable<number> {
    return this.stageService.resolution$;
  }

  get selectionChange$(): Observable<SelectedMapItem<MapItem> | undefined> {
    return this.mapCommunicationService.selectionChange$.pipe(
      delay(this.EXTERNAL_SELECTION_DEBOUNCE_TIME)
    );
  }

  get itemUnselected$(): Observable<MapItem> {
    return this.mapCommunicationService.itemUnselect$;
  }

  get isEditMode$(): Observable<boolean> {
    return this.mapCommunicationService.isEditMode$;
  }

  constructor(
    private readonly mapsStore: Store<fromMaps.MapsFeatureState>,
    private readonly stageService: StageService,
    protected readonly mapDataService: MapDataService,
    protected readonly mapCommunicationService: MapCommunicationService
  ) {
    this.setLayerFilter();
  }

  destroy(): void {
    this.ngUnsubscribe.next();

    this.isReady.next(false);

    this.list.forEach(view => view.destroy(true));
    this.list.clear();
    this.stageService.destroyStage();
  }

  createMap(): void {
    this.stageService.createStage();
    this.layerTypes.forEach(l => this.addLayerView(l));

    this.subscribeToEvents();
    this.isReady.next(true);
  }

  private subscribeToEvents(): void {
    this.mapDataService.subscribeToMapForSelected();
    this.subscribeToMapChanged();

    this.subscribeToFilterChanged();
    this.subscribeToModeChanged();
    this.subscribeToSelectionChanged();

    this.subscribeToLayerSelection();
  }

  // #region Add Layers
  getLayerView(layerType: LayerViewType): MapLayerView {
    return this.list.get(layerType) ?? this.addLayerView(layerType);
  }

  addLayerView(layerType: LayerViewType, view = new MapLayerView()): MapLayerView {
    if (view.isOnStage) return view;

    if (!this.stageService.created) {
      throw new Error('The Stage is not ready for adding layers');
    }

    this.list.set(layerType, view);
    this.stageService.createLayer(layerType, view);

    if (!this.layerTypes.some(t => t === layerType)) view.show();

    return view;
  }
  // #endregion

  // #region Map Load
  subscribeToMapChanged(): void {
    this.mapDataService.subscribeToMapForSelected();
    const mapChanged$ = this.mapDataService.mapChanged$.pipe(filterUndefined());

    mapChanged$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(m => this.stageService.setViewport(m.details));

    this.mapChanged$ = combineLatest([this.mapReady$, mapChanged$]).pipe(
      filter(([isCreated]) => isCreated),
      map(([, map]) => mapToReduced(map)),
      takeUntil(this.ngUnsubscribe)
    );
  }

  getImagePath(url: string): string {
    if (url === '') return url;

    return environment.Services.MapManager + url;
  }
  // #endregion

  // #region Resize
  requestResize(): void {
    if (this.stageService.ready) {
      this.stageService.resizeViewport();
    }
  }
  // #endregion

  // #region Filter
  private setLayerFilter(): void {
    this.filter.set(LayerViewType.NavigationLayers, (filter: MapsFilter) => filter.baseMap);
    this.filter.set(LayerViewType.Background, (filter: MapsFilter) => filter.backgroundMap);
    this.filter.set(LayerViewType.GraphLayer, (filter: MapsFilter) => filter.graphMap);
  }

  subscribeToFilterChanged(): void {
    this.mapFilterChanged$ = this.mapsStore.pipe(
      select(fromMaps.selectMapsFilters),
      takeUntil(this.ngUnsubscribe)
    );

    this.mapFilterChanged$.subscribe(this.onFiltersChange.bind(this));
  }

  onFiltersChange(filter?: MapsFilter): void {
    if (!filter) {
      return;
    }

    for (const f of this.filter.keys()) {
      const layer = this.getLayerView(f);
      const fnFilter = this.filter.get(f);

      layer.visible = fnFilter !== undefined ? fnFilter(filter) : false;
    }
  }
  // #endregion

  // #region Mode
  subscribeToModeChanged(): void {
    this.mapModeChanged$ = this.mapDataService.mode$.pipe(takeUntil(this.ngUnsubscribe));
    this.mapModeChanged$.subscribe(this.onModeChange.bind(this));

    combineLatest([this.mapModeChanged$, this.mapFilterChanged$])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([mode, filter]) => {
        if (mode === undefined || mode === MapMode.None) this.onFiltersChange(filter);
        else this.applyLayerModeRules(mode);
      });
  }

  onModeChange(mode: MapMode | undefined): void {
    this.stageService.setCursor(mode);
  }

  applyLayerModeRules(mode: MapMode | undefined): void {
    if (mode && mode === MapMode.RouteConfiguration) {
      this.setLayerVisibility(LayerViewType.GraphLayer, false);
    }
  }
  // #endregion

  // #region Selection
  subscribeToSelectionChanged(): void {
    this.selectionChange$
      .pipe(
        filter(i => i !== undefined && i.item && i.isFocused && this.validatePoint(i.item.position))
      )
      .subscribe(this.onSelectedMapItemChanged.bind(this));
  }

  validatePoint(point?: { x: number | undefined; y: number | undefined }): boolean {
    return point?.x !== undefined && point?.y !== undefined;
  }

  setSelectionMode(mode: MapSelectionMode): void {
    this.mapCommunicationService.setSelectionMode(mode);
  }

  resetSelectionMode(): void {
    this.mapCommunicationService.setSelectionMode(MapSelectionMode.Single);
  }

  onSelectedMapItemChanged(selected: SelectedMapItem<MapItem> | undefined): void {
    if (selected) this.stageService.setCenter(selected.item.position, true);
  }

  subscribeToLayerSelection(): void {
    this.stageService?.manageLayerSelection$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([event, args]) => this.handleLayerSelection(event, args));
  }

  private handleLayerSelection(event: InteractionEvent, args: PointerUpArgs): void {
    if (args.dragged) return;

    const item = event.target;
    const isViewPort = item instanceof Viewport;
    const isLayerView = item instanceof MapLayerView;
    const isMapItemContainerTarget = isMapItemContainer(item);

    if (this.mapCommunicationService.isSelectViewMode) {
      this.mapCommunicationService.selectMapLocation(args.position);
    } else if (this.mapCommunicationService.isSelectMultipleMode && isMapItemContainerTarget) {
      this.mapCommunicationService.requestSelectMultiple(item);
    } else if (isViewPort || isLayerView) {
      this.mapCommunicationService.requestClearSelection();
    } else if (isMapItemContainerTarget) {
      this.handleItemContainerSelection(item, args);
    } else {
      // Non-MapItems like the ZoneVertex
      this.mapCommunicationService.requestSelectionChange(item as MapItemContainer);
    }
  }

  private handleItemContainerSelection(item: MapItemContainer, args: PointerUpArgs) {
    if (item.type === MapItemType.Zone) {
      this.handleZoneItemContainerSelection(item, args);
    } else {
      // All other MapItem types
      this.mapCommunicationService.requestSelectionChange(item);
    }
  }

  private handleZoneItemContainerSelection(item: MapItemContainer, args: PointerUpArgs) {
    const zones = this.getZonesOverlap(args, item);

    if (zones.length > 1) {
      // Show select dialog
      this.handleSingleZoneSelection(item); // For the chosen zone on the dialog
    } else {
      this.handleSingleZoneSelection(item); // For single zone
    }
  }

  private handleSingleZoneSelection(item: MapItemContainer): void {
    if (this.shouldSetRuleZoneId(item) && isZoneMapItemContainer(item))
      this.mapCommunicationService.updateRuleZone(item);
    else this.mapCommunicationService.requestSelectionChange(item);
  }

  private getZonesOverlap(args: PointerUpArgs, item: MapItemContainer): GuidString[] {
    const layer = this.getLayerView(LayerViewType.Zones);

    if (isZoneMapLayerView(layer) && isZoneMapItemContainer(item)) {
      return layer.determineZonesOnPoint(args.position);
    }

    return [];
  }

  private shouldSetRuleZoneId(item: MapItem): boolean {
    return (
      item.type === MapItemType.Zone &&
      this.mapCommunicationService.selectedItemType === MapItemType.Node &&
      this.mapCommunicationService.isEditMode
    );
  }

  // #endregion

  // #region Layers
  setLayerVisibility(layerType: LayerViewType, show: boolean): void {
    const layer = this.getLayerView(layerType);
    layer.visible = show;
  }

  allowMapDrag(allow: boolean): void {
    this.stageService.allowMapDrag(allow);
  }
  // #endregion
}
