import { PoiType, StepType } from 'core/models';

export const SelectablePoiTypes = {
  [StepType.DockToCharge]: [PoiType.ChargingStation],
  [StepType.Dock]: [
    PoiType.DollyPlace,
    PoiType.TurnTableLarge,
    PoiType.AutomaticConveyorLoading,
    PoiType.TurnTableSmall,
    PoiType.Handover,
    PoiType.TripleTurnTable,
    PoiType.PalletStationCrosswise,
    PoiType.PalletStationLengthwise,
    PoiType.RackChangerDropoff,
    PoiType.RackChangerPickup,
  ],
  [StepType.TurnDolly]: [PoiType.DollyPlace],
  [StepType.Goto]: [
    PoiType.DollyPlace,
    PoiType.AutomaticConveyorLoading,
    PoiType.TurnTableLarge,
    PoiType.ParkingSpace,
    PoiType.WayPoint,
    PoiType.TurnTableSmall,
    PoiType.Handover,
    PoiType.AssignableWaypoint,
    PoiType.TripleTurnTable,
    PoiType.PalletStationCrosswise,
    PoiType.PalletStationLengthwise,
    PoiType.RackChangerDropoff,
    PoiType.RackChangerPickup,
  ],
  [StepType.WaitForEndOfStep]: [
    PoiType.AutomaticConveyorLoading,
    PoiType.ChargingStation,
    PoiType.DollyPlace,
    PoiType.ParkingSpace,
    PoiType.TurnTableLarge,
    PoiType.WayPoint,
    PoiType.TurnTableSmall,
    PoiType.Handover,
    PoiType.AssignableWaypoint,
    PoiType.TripleTurnTable,
  ],
  [StepType.GotoAndPushSideButton]: [PoiType.DollyPlace, PoiType.WayPoint],
} satisfies Partial<Record<StepType, PoiType[]>>;
